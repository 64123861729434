import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useLayoutStore from '../../../App/Stores/LayoutStore';
import useSchoolStore, { getSchoolCategoryShowIndex, schoolCategoriesToggleStatus, updateSchoolCategoryListItemOrder } from '../../../App/Stores/SchoolStore';
import { iBlueAddItem, iCategory, iEdiItIcon, iWhiteAddItem } from '../../../App/Utility/source';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../Components/Button/CommonButton';
import CommonButtonOutlined from '../../../Components/Button/CommonButtonOutlined';
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import DraggableList from '../../../Components/DraggableLists/DraggableList';
import Image from '../../../Components/Image/Image';
import BackLink from '../../../Components/Pagination/BackLink';
import GreenSwitch from '../../../Components/Switch/GreenSwitch';
import CommonTable from '../../../Components/Table/CommonTable';
import SchoolCategoryLessonTableRow from '../../../Components/Table/School/SchoolCategoryLessonTableRow';
import CommonTitle from '../../../Components/Title/CommonTitle';
import { PageTitle } from '../../../Utility/UtilityFunctions';



function SchoolCategoryDetails() {

    const [enabled, setEnabled] = useState(false);
    const [isDragMode, setIsDragMode] = useState(false);

    const { school_id, school_category_id } = useParams();

    const {
        schoolCategoryShow,
        setShowSchoolCategoryDeactivateModal,
        setShowAddSchoolCategoryModal,
        setShowEditSchoolCategoryModal }
        = useSchoolStore();

    const HandleDeactivate = async () => {
        // await setSchoolDeactivateID(data.school?.id)
        console.log("enabled")
        if (enabled === true) {
            await setShowSchoolCategoryDeactivateModal(true);
        } else {
            // const body = { "id": school_category_id, "school_id": school_id }
            let activateSuccess = await schoolCategoriesToggleStatus(school_category_id, school_id);
            if (activateSuccess) setEnabled(true);
        }
    }

    const schoolCategoryHeaders = [
        { index: 1, name: t("#") },
        { index: 2, name: t("Name") },
        { index: 3, name: t("Duration") },
        { index: 4, name: t("Status") },
        { index: 5, name: t(" Price") },
    ];

    useEffect(() => {
        fetchSchoolCategoryShow()
    }, [school_id])

    const fetchSchoolCategoryShow = async () => {
        await getSchoolCategoryShowIndex(school_category_id)
    }

    useEffect(() => {
        setEnabled(schoolCategoryShow?.is_active === 1 || schoolCategoryShow?.is_active === '1' ? true : false)
    }, [schoolCategoryShow])

    const { setBarTitle } = useLayoutStore();

    useEffect(() => {
        window.scrollTo(0, 0);
        PageTitle(t("School Categories Details"));
        setBarTitle("School Categories Details");
    }, [])



    //console.log("schoolCategoryShow", schoolCategoryShow)


    return (
        <div className='mx-s12 md:mx-s32'>

            <CommonTitle title="School Categories Details" >
                <BackLink linksArray={[
                    { label: "school", linkTo: "/school" },
                    { label: "School Profile", linkTo: `/school/details/${school_id}` },
                    { label: "School Categories", linkTo: `/school/details/${school_id}/category` },
                    { label: "School Categories Details", linkTo: "" },
                ]} />

            </CommonTitle>

            <div className="bg-cBrandColor2 rounded-br20 py-s20 overflow-hidden">

                <div className='px-s20'>
                    <div className='flex justify-between'>
                        <div className='flex'>
                            <div className='rounded-full w-s88 h-s88 py-s12 pl-s12 pr-s10 bg-cBackgroundAndCategory'>
                                <Image className='h-s55 w-s65' src={schoolCategoryShow?.category?.icon} dummyImage={iCategory} isCategoryImage={true} />
                            </div>
                            <div className='ml-s14'>
                                <div className='text-cHighlighted text-fs20 font-fw600'>
                                    {schoolCategoryShow?.category?.name}
                                </div>
                                <div className='text-cTextGray text-fs16 font-fw400 mt-s3'>
                                    Lesson : {schoolCategoryShow?.package_lesson ? schoolCategoryShow?.package_lesson :
                                        <CommonEmptyStatus textColor='text-cTextGray' fontWeight='font-fw400' size='text-fs16' />}
                                </div>
                                <div className='text-cTextGray text-fs16 font-fw400'>
                                    {toHoursAndMinutes(schoolCategoryShow?.package_duration)}
                                </div>
                            </div>
                        </div>

                        <div className='flex mt-s40'>
                            <div className={`${enabled ? 'text-cPassed' : 'text-cFailed'} 
                                       text-fs14 font-fw400 text-center`}>
                                {enabled ? 'Active' : <span className=''>Deactivate</span>}
                            </div>
                            <div className='flex justify-center ml-s10'>
                                <GreenSwitch
                                    enabled={enabled}
                                    setEnabled={() => HandleDeactivate()}
                                />
                            </div>
                            <img onClick={() => {
                                setShowEditSchoolCategoryModal(true)
                            }}
                                className='cursor-pointer w-s25 h-s25 ml-s25 mr-s6' src={iEdiItIcon} alt="" />
                        </div>
                    </div>
                </div>

                <div className="pt-s20 px-1">
                    <hr />
                </div>

                <div className="pl-5 pt-5">
                    {!isDragMode ? <CommonButton onClick={() => setIsDragMode(true)} width='w-[200px]' btnLabel='Change Lesson Orders' /> : ""}
                </div>

                {!isDragMode ? <CommonTable
                    headers={schoolCategoryHeaders}
                    tableTitle="Lessons"
                    shoSearchBox={false}
                    pagination={false}
                    showPageCountText={true}
                    showPagination={false}
                    titleComponent={
                        <div className='mb-s20'>
                            <CommonButtonOutlined
                                iconLeft={iWhiteAddItem}
                                iconLeftHover={iBlueAddItem}
                                colorType='primary'
                                btnLabel="Add Lesson"
                                onClick={() => { setShowAddSchoolCategoryModal(true) }}
                            />
                        </div>
                    }

                    items={
                        <>
                            {schoolCategoryShow?.lessons?.length > 0 ?
                                schoolCategoryShow?.lessons?.map((item, index) => (
                                    <SchoolCategoryLessonTableRow data={item} key={index} index={index} />
                                )) :
                                <tr className='w-full'>
                                    <th colSpan={5} className="py-s10">
                                        No Data Found !
                                    </th>
                                </tr>
                            }
                        </>
                    }
                />
                    :
                    <div className="px-5">
                        <div className="pb-5 font-semibold text-fs20">Change Lesson Order</div>
                        <DraggableList onSubmit={(res) => {
                            console.log("RES ::: ", res);
                            let updateSuccess = updateSchoolCategoryListItemOrder(res, school_category_id);
                            if (updateSuccess) setIsDragMode(false);
                        }} />

                    </div>

                }

                <div className='px-s20'>
                    <span className='mr-s5 text-fs14 font-fw600 text-cBlack leading-5'>Description</span>
                    <div className='mt-s5'>
                        {schoolCategoryShow?.description ? schoolCategoryShow?.description :
                            <CommonEmptyStatus size='text-fs14' textColor='text-cBlack' fontWeight='font-fw400' leading="leading-5" />}
                    </div>
                    <div className='mt-s20'>
                        <span className='mr-s5 text-fs14 font-fw600 text-cBlack leading-5'>Requirement</span>
                        <div className='mt-s5'>
                            {schoolCategoryShow?.requirement ? schoolCategoryShow?.requirement
                                : <CommonEmptyStatus size='text-fs14' textColor='text-cBlack' fontWeight='font-fw400' leading="leading-5" />}
                        </div>
                    </div>

                    {/* <CommonInput value="5" />
                    <div className='mt-s20'></div>
                    <input type="checkbox"></input>
                    <span className='text-fs14 font-fw600 text-cBlack ml-s10 pb-s10'>Buy full package.</span>

                    <div className='flex justify-center mt-s20'>
                        <CommonButton btnLabel="Save Changes" roundedFull="true" />
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default SchoolCategoryDetails