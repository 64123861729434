import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import useInvoiceStore from '../../../App/Stores/InvoiceStore';
import { iRightArrow } from '../../../App/Utility/source';
import { dateDiffCalendar } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';
import { iUserAvatar } from './../../../App/Utility/source';

const SchoolTableRow = ({ data, index }) => {

  const navigate = useNavigate();

  const { setInvoiceSearchKey } = useInvoiceStore();

  const location = useLocation();

  const { id } = useParams()

  const Click = () => {
    // console.log("DATA::: ", data);
    // return
    setInvoiceSearchKey("")
    if (location.pathname === `/category/school/${id}`) {
      navigate(`/category/school/profile/${id}`);
    }
    else {
      navigate(`/school/details/${data?.id}`);
    }
  }


  return (
    <>
      <tr onClick={() => { Click() }} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >
        <th className='m-2 font-normal text-center border-r py-s10'>
          {index}
        </th>

        {/* name with image */}
        <td className='border-r-[1px] py-s10 text-left pl-s10 min-w-[190px] max-w-[190px] 2xl:min-w-[240px] 2xl:max-w-[240px] relative'>
          <span className='flex flex-row items-center'>
            <div className='min-w-[45px] max-w-[45px]'>
              <Image cursorPointerClass="cursor-pointer" className='rounded-full w-w44 h-h44 grow-0' src={data?.image} dummyImage={iUserAvatar} />
            </div>
            <div className="items-center truncate font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12"
            >{data?.name ?
              <div>
                <Tooltip title={data?.name}>
                  <div className='max-w-[130px] 2xl:max-w-[200px] truncate'>{data?.name}</div>
                </Tooltip>

                <div className={`capitalize text-cImportantText text-fs9 max-w-[150px] 2xl:max-w-[200px] truncate`}
                >{`${data?.student_counter?.active} Active ${data?.student_counter?.pending} Pending 
                ${data?.student_counter?.history} History`}

                </div></div>
              : <CommonEmptyStatus />}
            </div>
          </span>
        </td>

        {/* cvr number */}
        <td className='py-s10 border-r-[1px] px-s10 text-fs14 text-center text-cTextBody font-fw400 
        min-w-[80px] max-w-[80px]'>
          {data?.cvr === "null" || data?.cvr === null ? <CommonEmptyStatus /> : data?.cvr ? data?.cvr : <CommonEmptyStatus />}
        </td>

        {/* email address */}
        <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[140px] max-w-[140px]
        2xl:min-w-[200px] 2xl:max-w-[200px]'>
          <Tooltip title={data?.email}>
            <div className='font-fw400 text-cImportantText text-fs14 truncate'>{data?.email === "null" || data?.email === null ? <CommonEmptyStatus /> : data?.email}</div>
          </Tooltip>
        </td>

        {/* phone number */}
        <td className='py-s10 border-r-[1px] px-s10 text-fs14 text-center text-cTextBody  min-w-[85px] max-w-[85px] truncate'>
          <span className=' truncate font-fw400 text-cImportantText text-fs14'>
            {data?.phone === "null" || data?.phone === null ? <CommonEmptyStatus /> : <Tooltip title={data?.phone}><span>{data?.phone}</span></Tooltip>}</span>
        </td>

        {/* license info */}
        <td className={`p-s10 border-r-[1px] text-center 
         ${data?.license_title === "null" || data?.license_title === null ? "min-w-[100px] max-w-[100px]" : "min-w-[210px] max-w-[210px]"}`}>
          <div className=''>
            <span className='text-fs14 font-fw500 text-cHighlighted'>
              {data?.license_title === "null" || data?.license_title === null ? <CommonEmptyStatus /> : data?.license_title}
            </span>
            {
              data?.license_start_date ?
                <div className='font-fw400 text-cImportantText text-fs12'>
                  {dateDiffCalendar(new Date(), data?.license_end_date)}{" remaining"}
                  {/* ({data?.license_end_date && differenceInDaysDate(new Date((data?.license_end_date))) >= 0 ? getFormatedStringFromDays(differenceInDaysDate(new Date((data?.license_end_date)))) : "0 days"} remaining) */}
                </div> : ""}
            <div>
              {
                data?.license_status === 'requested' || data?.license_status === 'paid1' || data?.license_status === 'missing1' ||
                  data?.license_status === 'created' || data?.license_status === 'paid2' || data?.license_status === 'missing2'
                  ? ' (Applied)' : ''}</div>
          </div>
        </td>

        {/* status of account */}
        <td className='py-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[100px] max-w-[100px]'>
          <div className='flex justify-center items-center space-x-2.5'>
            <div className={`font-fw600 text-fs14 ${data?.is_active ? "text-cPassed" : "text-cCancelled"}`}>{data?.is_active ?
              "Active" : "Deactivate"}
            </div>
            <img className='pt-s5' src={iRightArrow} alt=""></img>
          </div>
        </td>
      </tr>
    </>
  )
}

export default SchoolTableRow;