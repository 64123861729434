import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useInvoiceStore from '../../../App/Stores/InvoiceStore';
import { iBackArrow, iRightArrow } from '../../../App/Utility/source';
import { dateDiffCalendar } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../../Components/Image/Image';
import { formatDate } from '../../../Utility/UtilityFunctions';

function SchoolInvoiceTableRow({ data, index }) {
    const { schoolInvoiceHistoryMode } = useInvoiceStore();

    const [invoiceStatus, setInvoiceStatus] = useState("");
    const [basicData, setBasicData] = useState({
        image: "",
        name: "",
        email: "",
        role: "",
    });

    const navigate = useNavigate();

    const { school_user_id, school_id } = useParams();

    const invoiceClick = () => {
        // console.log(data);
        // console.log("LINK::::", (`/school/details/${school_id}/invoice/${school_user_id}/details/${data?.id}/${data?.invoice_type}`));
        navigate(`/school/details/${school_id}/invoice/${school_user_id}/details/${data?.id}/${data?.invoice_type}`)
        return
    }

    useEffect(() => {

        switch (data?.invoice_status) {
            case "accepted":
                setInvoiceStatus("accepted");
                break;
            case "rejected":
                setInvoiceStatus("rejected");
                break;
            case "cancelled":
                setInvoiceStatus("cancelled");
                break;
            case "created":
                setInvoiceStatus("created");
                break;
            case "requested":
                setInvoiceStatus("requested");
                break;
            case "expired":
                setInvoiceStatus("expired");
                break;
            case "paid1":
                setInvoiceStatus("paid");
                break;
            case "paid2":
                setInvoiceStatus("paid");
                break;
            case "missing1":
                setInvoiceStatus("missing payment");
                break;
            case "missing2":
                setInvoiceStatus("missing payment");
                break;

            default:
                break;
        }

        if (data?.from_role === "admin") {
            setBasicData({
                email: data?.to_email,
                image: data?.to_image,
                name: data?.to_name,
                role: data?.to_role,
            });
        } else {
            setBasicData({
                email: data?.from_email,
                image: data?.from_image,
                name: data?.from_name,
                role: data?.from_role,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <>
            <tr
                onClick={() => { invoiceClick() }}
                className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`}
            >
                <th className='font-normal text-center border-r p-s10'>
                    <span className=''>{index}</span>
                </th>

                {/* name with image */}
                <td className='border-r-[1px] min-w-[180px] 2xl:min-w-[250px]'>
                    <span className='flex items-center py-s10'>
                        <div className='pl-s5 min-w-[50px] max-w-[50px]'>
                            <Image cursorPointerClass="cursor-pointer" className='rounded-full w-w44 h-h44 grow-0' src={basicData?.image} alt="" />
                        </div>
                        <div className="items-center truncate font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12" >
                            {basicData?.name ?
                                <Tooltip title={basicData?.name}>
                                    <div className='max-w-[130px] 2xl:max-w-[200px] truncate'>{basicData?.name}</div>
                                </Tooltip>
                                : <CommonEmptyStatus />}
                        </div>
                    </span>
                </td>

                {/* email address */}
                <td className='py-s10 border-r-[1px] px-s10 text-fs14 text-center text-cTextBody min-w-[150px] 2xl:min-w-[200px]'>
                    <Tooltip title={basicData?.email}>
                        <div className='font-fw400 text-cImportantText text-fs14 max-w-[150px] 2xl:max-w-[200px] truncate'>{basicData?.email === "null" || basicData?.email === null ? <CommonEmptyStatus /> : basicData?.email}</div>
                    </Tooltip>
                </td>

                {/* e       invoice id */}
                <td className='py-s10 border-r-[1px] px-s5 text-fs14 font-fw400 text-center min-w-[100px] text-cTextBody'>
                    <span className='capitalize font-fw400 text-cImportantText text-fs14'>{data?.invoice_id ?? <CommonEmptyStatus />}</span>
                </td>

                {/* user type */}
                <td className='py-s10 border-r-[1px] px-s10 text-fs14 font-fw400 text-center min-w-[120px] text-cTextBody'>
                    {schoolInvoiceHistoryMode ?
                        <span className='flex justify-center items-center space-x-2'>
                            <span className='capitalize font-fw400 text-cImportantText text-fs14'>{data?.from_role ?? <CommonEmptyStatus />}</span>
                            <img src={iBackArrow} alt="" className='rotate-180 h-s13' />
                            <span className='capitalize font-fw400 text-cImportantText text-fs14'>{data?.to_role ?? <CommonEmptyStatus />}</span>
                        </span>
                        : <span className='capitalize font-fw400 text-cImportantText text-fs14'>{basicData?.role ?? <CommonEmptyStatus />}</span>}
                </td>

                {/* create date */}
                <td className='py-s10 border-r-[1px] px-s5 text-fs14 font-fw400 text-center min-w-[150px] text-cTextBody'>
                    {data?.created_at === "null" ? <CommonEmptyStatus /> : formatDate(data?.created_at)}
                </td>

                {/* b       last action */}
                <td className='py-s10 border-r-[1px] px-s5 text-center min-w-[150px]'>
                    <span className='font-semibold capitalize text-fs14 text-cMainBlack'>{data?.last_action_user ?? <CommonEmptyStatus />}</span> <br></br>
                    <span className='text-cImportantText test-fs12 font-fw400'>
                        {data?.last_action_date ?
                            <div className='font-fw400 text-cImportantText text-fs12'>
                                {dateDiffCalendar(new Date(data?.last_action_date), new Date(), true)}{" ago"}
                            </div> : ""}
                    </span>
                </td>

                {/* status type */}
                <td className='py-s10 border-r-[1px] text-fs12 text-cTextBody relative min-w-[100px] max-w-[100px]'>
                    <div className="flex justify-center items-center space-x-s5">
                        <div className='text-center capitalize text-cImportantText text-fs12'>{invoiceStatus}</div>
                        <img className="mt-s2" src={iRightArrow} alt="" />
                    </div>
                </td>

            </tr>
        </>
    )
}
export default SchoolInvoiceTableRow