import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import useBannerStore, {
  createNewBanner,
} from "../../../App/Stores/bannerStore";
import { Toastr } from "../../../App/Utility/UtilityFunctions";
import CommonButton from "../../../Components/Button/CommonButton";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";

const AddBannerModal = () => {
  const { showAddBannerModal, setShowAddBannerModal } = useBannerStore();

  const [addForm, setAddForm] = useState({
    name: "",
    app_type: "",
    content: "",
  });

  // const Upload = () => {
  //   //Get reference of FileUpload.
  //   var fileUpload = document.getElementById("fileUpload");

  //   //Check whether the file is valid Image.
  //   var regex = new RegExp("([a-zA-Z0-9s_\\.-:])+(.jpg|.png|.gif)$");
  //   if (regex.test(fileUpload.value)) {
  //     //Check whether HTML5 is supported.
  //     if (typeof fileUpload.files != "undefined") {
  //       //Initiate the FileReader object.
  //       var reader = new FileReader();
  //       //Read the contents of Image File.
  //       reader.readAsDataURL(fileUpload.files[0]);
  //       reader.onload = function (e) {
  //         //Initiate the JavaScript Image object.
  //         var image = new Image();

  //         //Set the Base64 string return from FileReader as source.
  //         image.src = e.target.result;

  //         //Validate the File Height and Width.
  //         image.onload = function () {
  //           var height = this.height;
  //           var width = this.width;
  //           if (height > 100 || width > 100) {
  //             alert("Height and Width must not exceed 100px.");
  //             return false;
  //           }
  //           alert("Uploaded image has valid Height and Width.");
  //           return true;
  //         };
  //       };
  //     } else {
  //       alert("This browser does not support HTML5.");
  //       return false;
  //     }
  //   } else {
  //     alert("Please select a valid Image file.");
  //     return false;
  //   }
  // };

  const [image, setImage] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: addForm.name,
      content: addForm.content,
      app_type: addForm.app_type,
      image: image,
    };

    if (addForm.app_type === "")
      return Toastr({ message: "User Not Select", type: "warning" });
    if (image === "")
      return Toastr({ message: "Image Not Select", type: "warning" });
    if (addForm?.name && addForm?.app_type && addForm?.content) {
      let addSuccess = await createNewBanner(data);
      if (addSuccess) {
        setShowAddBannerModal(false);
        setAddForm({ name: "", app_type: "", content: "" });
        setImage("");
      }
    }
  };
  return (
    <div>
      <CommonModal
        showModal={showAddBannerModal}
        setShowModal={setShowAddBannerModal}
        modalTitle="Add Banner"
        mainContent={
          <>
            <form onSubmit={formSubmit}>
              <div className="flex justify-center pt-5">
                <ProfileImageUploader
                  //id="fileUpload"
                  iImage={image}
                  rounded={false}
                  setImage={setImage}
                  bannerImage={true}
                />
              </div>
              <div className="my-2">
                <p className="text-center text-cWarningText text-fs14">
                  For better experience, Choose image of max-width 1200px,
                  max-height 600px.
                </p>
              </div>

              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Title"
                  className3="cHighlightedTexts"
                  placeholder="Banner Title"
                  max_input={20}
                  onChange={(e) =>
                    setAddForm({ ...addForm, name: e.target.value })
                  }
                />
              </div>
              <div className="">
                <FormControl className="w-full">
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    <h1 className="w-full text-cHighlighted text-fs14 font-fw600 mb-s15">
                      Select User Type <span className="text-cWarning">*</span>
                    </h1>
                  </FormLabel>
                  <RadioGroup
                    className="w-full"
                    required={true}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <div className="flex justify-between space-x-5 w-full">
                      <div className="w-full h-s55  mb-s8 flex justify-center border-[1px] border-cBackgroundAndCategory rounded-br10">
                        <FormControlLabel
                          value="student"
                          control={<Radio required={true} />}
                          label="Student"
                          onChange={(e) =>
                            setAddForm({ ...addForm, app_type: e.target.value })
                          }
                        />
                      </div>
                      <div className="w-full h-s55  mb-s8 flex justify-center border-[1px] border-cBackgroundAndCategory rounded-br10">
                        <FormControlLabel
                          value="school"
                          control={<Radio required={true} />}
                          label="School"
                          onChange={(e) =>
                            setAddForm({ ...addForm, app_type: e.target.value })
                          }
                        />
                      </div>
                      <div className="w-full h-s55 mb-s8 flex justify-center border-[1px] border-cBackgroundAndCategory rounded-br10">
                        <FormControlLabel
                          value="instructor"
                          control={<Radio required={true} />}
                          label="Instructor"
                          onChange={(e) =>
                            setAddForm({ ...addForm, app_type: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Description"
                  rows="3"
                  textarea="true"
                  placeholder="Write description"
                  onChange={(e) =>
                    setAddForm({ ...addForm, content: e.target.value })
                  }
                />
              </div>

              <div className="flex justify-center mt-s20">
                <CommonButton
                  // onClick={async (e) => {
                  //   if (addFrom.title == null) {
                  //     console.log("The title is null");
                  //   }
                  //   // if (
                  //   //   addFrom.title &&
                  //   //   addFrom.user_type &&
                  //   //   addFrom.description
                  //   // ) {
                  //   //   let addSuccess = await createNewBanner(
                  //   //     addFrom,
                  //   //     withImage
                  //   //   );
                  //   //   if (addSuccess) {
                  //   //     console.log("addSuccess::::", addSuccess);
                  //   //     setClearImage(true);
                  //   //     setWithImage("");
                  //   //     setAddForm({
                  //   //       title: "",
                  //   //       image: "",
                  //   //       user_type: "",
                  //   //       description: "",
                  //   //     });
                  //   //     e.preventDefault();
                  //   //   }
                  //   // }
                  //   setShowAddBannerModal(false);
                  //   //console.log(addFrom, withImage);
                  // }}
                  type="submit"
                  btnLabel="Add Banner"
                  colorType="primary"
                  roundedFull="true"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default AddBannerModal;
