import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useBannerStore, { editBanner } from "../../../App/Stores/bannerStore";
import CommonButton from "../../../Components/Button/CommonButton";
import ProfileImageUploader from "../../../Components/Image/ProfileImageUploader";
import CommonInput from "../../../Components/Input/CommonInput";
import CommonModal from "../../../Components/Modal/CommonModal";
import { BaseUrlSrc } from "./../../../App/Utility/Url";

const EditBannerModal = () => {
  const {
    showEditBannerModal,
    setshowEditBannerModal,
    getSingleBannerDetails,
    setEditFormData,
  } = useBannerStore();

  const [addForm, setAddForm] = useState({
    id: "",
    name: "",
    content: "",
    app_type: "",
    image: "",
  });

  const [withImage, setWithImage] = useState("");

  const formSubmit = async (e) => {
    e.preventDefault();
    setEditFormData("name", addForm.name);
    setEditFormData("content", addForm.content);
    setEditFormData("app_type", addForm.app_type);
    setEditFormData("id", addForm.id);

    if (addForm?.name && addForm?.content && addForm?.app_type) {
      let addSuccess = await editBanner(addForm);
      await setEditFormData;
      if (addSuccess) {
        // await setAddForm({id: "",name: "",content: "",app_type: '',image: ''});
        setEditFormData("name", "");
        setEditFormData("content", "");
        setEditFormData("app_type", "");
        setEditFormData("id", "");
        setEditFormData("image", "");
        await setshowEditBannerModal(false);
      }
    }
  };

  useEffect(() => {
    setAddForm({
      id: getSingleBannerDetails?.id,
      name: getSingleBannerDetails?.name,
      content: getSingleBannerDetails?.content,
      app_type: getSingleBannerDetails?.app_type,
      image: getSingleBannerDetails?.image,
    });
    setWithImage(getSingleBannerDetails?.app_type);
  }, [getSingleBannerDetails]);

  //console.log("getSingleBannerDetails", getSingleBannerDetails)

  return (
    <div>
      <CommonModal
        showModal={showEditBannerModal}
        setShowModal={setshowEditBannerModal}
        modalTitle="Edit Banner"
        mainContent={
          <>
            <div className="flex justify-center pt-5">
              <ProfileImageUploader
                iImage={addForm?.image ? BaseUrlSrc + addForm?.image : ""}
                imageUploader={setEditFormData}
                imageName="image"
                rounded={false}
                bannerImage={true}
              />
            </div>
            <div className="my-2">
              <p className="text-center text-amber-500 text-fs14">
                For better experience, Choose image of max-width 1200px,
                max-height 600px.
              </p>
            </div>

            <form onSubmit={formSubmit}>
              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Edit Title "
                  className3="cHighlightedTexts"
                  placeholder="Write Banner Title"
                  max_input={20}
                  value={addForm?.name}
                  onChange={(e) => {
                    setAddForm({ ...addForm, name: e.target.value });
                  }}
                />
              </div>

              <div className="">
                <FormControl className="w-full">
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    <h1 className="w-full font-medium text-cInputLabel text-fs16 mb-s15">
                      Select User Type
                    </h1>
                  </FormLabel>

                  <RadioGroup
                    className="w-full"
                    required={true}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <div className="flex justify-between space-x-5 w-full">
                      <div className="w-s200 h-s55 mr-s8 mb-s8 flex justify-center border-[1px] border-cBackgroundAndCategory rounded-br10">
                        <FormControlLabel
                          value="student"
                          control={<Radio required={true}/>}
                          label="Student"
                          checked={
                            addForm?.app_type === "student" ? true : false
                          }
                          onChange={(e) =>
                            setAddForm({ ...addForm, app_type: e.target.value })
                          }
                        />
                      </div>
                      <div className="w-s200 h-s55 mr-s8 mb-s8 flex justify-center border-[1px] border-cBackgroundAndCategory rounded-br10">
                        <FormControlLabel
                          value="school"
                          control={<Radio  required={true}/>}
                          label="School"
                          checked={
                            addForm?.app_type === "school" ? true : false
                          }
                          onChange={(e) =>
                            setAddForm({ ...addForm, app_type: e.target.value })
                          }
                        />
                      </div>
                      <div className="w-s200 h-s55 mr-s8 mb-s8 flex justify-center border-[1px] border-cBackgroundAndCategory rounded-br10">
                        <FormControlLabel
                          value="instructor"
                          control={<Radio required={true}/>}
                          label="Instructor"
                          checked={
                            addForm?.app_type === "instructor" ? true : false
                          }
                          onChange={(e) =>
                            setAddForm({ ...addForm, app_type: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="my-s20">
                <CommonInput
                  required={true}
                  label="Description"
                  rows="4"
                  textarea="true"
                  placeholder="Write description"
                  value={addForm?.content}
                  onChange={(e) => {
                    setAddForm({ ...addForm, content: e.target.value });
                  }}
                />
              </div>
              <div className="flex justify-center mt-s20">
                <CommonButton
                  // onClick={async()=>{await setAddForm({ ...addForm, image: image })
                  // console.log("addForm.image",addForm.image)}}
                  type="submit"
                  btnLabel="Update"
                  colorType="primary"
                  roundedFull="true"
                  //width="w-s280"
                />
              </div>
            </form>
          </>
        }
      />
    </div>
  );
};

export default EditBannerModal;
