import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getInstructorDetails } from '../../App/Stores/InstructorStore';
import useLicenseStore, { assignLicense, getLicenseIndex } from '../../App/Stores/LicenseStore';
import { getSchoolDetail } from '../../App/Stores/SchoolStore';
import CommonButton from '../../Components/Button/CommonButton';
import PremiumCard from '../../Components/Card/PremiumCard';
import CommonModal from '../../Components/Modal/CommonModal';

const AssignLicenseModal = () => {
    const { assignLicenseModal, setAssignLicenseModal, licenseIndex, assignLicenseType, setAssignLicenseForm, assignLicenseForm } = useLicenseStore();
    useEffect(() => { if (assignLicenseModal) getLicenseIndex(); }, [assignLicenseModal]);

    const { school_id, instructor_id } = useParams();

    return (
        <CommonModal
            showModal={assignLicenseModal}
            setShowModal={setAssignLicenseModal}
            modalTitle={("Assign License")}
            mainContent={
                <div className='pt-5'>
                    <div className="pb-2.5 font-semibold">Please Select a license package:</div>
                    <div className="flex items-center w-full overflow-x-auto space-x-2.5">
                        {licenseIndex?.data?.length > 0 ?
                            licenseIndex?.data?.map((item, index) =>
                                (item?.type === assignLicenseType && item?.is_active) ?
                                    <div className='h-[240px] p-1'>
                                        <PremiumCard
                                            data={item}
                                            key={index}
                                            editOption={true}
                                            showControls={false}
                                            linked={false}
                                            selected={assignLicenseForm?.license_id === item?.id ? true : false}
                                            onClick={() => { console.log("license details: ", item?.id); setAssignLicenseForm({ ...assignLicenseForm, license_id: item?.id }); }}
                                        />
                                    </div>
                                    : "") : ""
                        }
                    </div>
                    <div className="pt-5 flex justify-center">
                        <CommonButton
                            onClick={async () => {
                                console.log("assignLicenseForm::::", assignLicenseForm);
                                console.log("ids    ::::", school_id, instructor_id);
                                // return;
                                let assignSuccess = await assignLicense(assignLicenseForm);
                                if (assignSuccess) {
                                    if (assignLicenseType === "school") await getSchoolDetail(school_id);
                                    else await getInstructorDetails(instructor_id);
                                    await setAssignLicenseForm({ license_id: 0, user_id: 0 });
                                    setAssignLicenseModal(false);
                                }
                            }}
                            isDisabled={assignLicenseForm?.license_id === 0 ? true : false}
                            colorType={assignLicenseForm?.license_id === 0 ? "basic" : "primary"}
                            btnLabel="Submit"
                        />
                    </div>
                </div>
            }
        />
    )
}

export default AssignLicenseModal