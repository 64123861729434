import create from "zustand";
import useUtilityStore from "./UtilityStore";
import axios from "axios";
import { getChatDetailUrl, getContactUsListUrl, getWebContactUsListUrl, messageSeenUrl, messageSendUrl, messageUnSeenUrl, sendContactWebReplyUrl, updateContactWebSeenUrl } from './../Utility/Url';
import { Toastr } from "../Utility/UtilityFunctions";
import { getDashboardData, getDashboardPendingActivityData } from "./DashboardStore";

const { setLoading, setLoadingSearch } = useUtilityStore.getState();


const useContactStore = create((set) => ({

  contactUsListAll: [],
  setContactUsListAll: (value) => set({ contactUsListAll: value }),

  contactWebUsListAll: [],
  setContactWebUsListAll: (value) => set({ contactWebUsListAll: value }),

  chatDetails: [],
  setChatDetails: (value) => set({ chatDetails: value }),

  contactWebDetails: {},
  setContactWebDetails: (value) => set({ contactWebDetails: value }),

  contactUsSearch: "",
  setContactUsSearch: (value) => set({ contactUsSearch: value }),

  contactWebSearch: "",
  setContactWebSearch: (value) => set({ contactWebSearch: value }),

  FilterContactActive: false,
  setFilterContactActive: (value) => set({ FilterContactActive: value }),

  FilterContactWebActive: false,
  setFilterContactWebActive: (value) => set({ FilterContactWebActive: value }),

  contactUsTakeList: { take: 10 },
  setContactUsTakeList: (name, value) => set((state) => (state.contactUsTakeList[name] = value)),

  contactWebTakeList: { take: 10 },
  setContactWebTakeList: (name, value) => set((state) => (state.contactWebTakeList[name] = value)),

  contactUsPageUrl: { url: "" },
  setContactUsPageUrl: (name, value) => set((state) => (state.contactUsPageUrl[name] = value)),

  contactWebPageUrl: "",
  setContactWebPageUrl: (name, value) => set((state) => (state.contactWebPageUrl = value)),

  //modal
  showContactMessageDetails: false,
  setShowContactMessageDetails: (value) => set({ showContactMessageDetails: value }),

  showContactWebMessageDetails: false,
  setShowContactWebMessageDetails: (value) => set({ showContactWebMessageDetails: value }),

  showFilterMessage: false,
  setShowFilterMessage: (value) => set({ showFilterMessage: value }),

  showFilterWebMessage: false,
  setShowFilterWebMessage: (value) => set({ showFilterWebMessage: value }),

  showReplyMessage: false,
  setShowReplyMessage: (showReplyMessage) => set({ showReplyMessage }),

  showDeleteMessage: false,
  setShowDeleteMessage: (showDeleteMessage) => set({ showDeleteMessage }),

  customDateShow: false,
  setCustomDateShow: (customDateShow) => set({ customDateShow }),

  messageUserID: 0,
  setMessageUserID: (messageUserID) => set({ messageUserID }),

  selectedMessageID: 0,
  setSelectedMessageID: (selectedMessageID) => set({ selectedMessageID }),

  contactMessageReply: "",
  setContactMessageReply: (value) => set({ contactMessageReply: value }),

  contactMessageList: [],
  setContactMessageList: (contactMessageList) => set({ contactMessageList }),

  filterMode: false,
  setFilterMode: (filterMode) => set({ filterMode }),


  filterForm: {
    status: "",
    is_reply: null,
    is_seen: null,
    date: "",
    custom_date: "",
    start_date: "",
    end_date: "",
  },
  setFilterForm: (value) => set({ filterForm: value }),
  resetContactUsFilterData: (value) => set({
    filterForm: {
      status: "",
      is_reply: null,
      is_seen: null,
      date: "",
      custom_date: "",
      start_date: "",
      end_date: "",
    }
  }),


  filterFormWeb: {
    status: "",
    is_reply: null,
    is_seen: null,
    date: "",
    custom_date: "",
    start_date: "",
    end_date: "",
  },
  setFilterFormWeb: (value) => set({ filterFormWeb: value }),

}));

export default useContactStore;

const { contactUsPageUrl, filterForm, setContactUsListAll, setFilterContactActive, contactUsTakeList } = useContactStore.getState();

// get all contact app    
export const getContactUsList = async (url = "", data) => {
  let filterForm = { ...data };
  if (filterForm?.status === "") delete filterForm.status;
  if (filterForm?.is_reply === null) delete filterForm.is_reply;
  if (filterForm?.is_seen === null) delete filterForm.is_seen;
  if (filterForm?.date === "") delete filterForm.date;
  if (filterForm?.custom_date === "") delete filterForm.custom_date;
  if (filterForm?.start_date === "") delete filterForm.start_date;
  if (filterForm?.end_date === "") delete filterForm.end_date;
  // return
  console.log("filterForm", filterForm)

  if (Object.keys(filterForm).length > 0) {
    setFilterContactActive(true);
    filterForm = { ...filterForm, take: contactUsTakeList.take };
  } else {
    setFilterContactActive(false);
    filterForm = { take: contactUsTakeList.take };
  }


  try {
    setLoading(true);
    const body = { ...filterForm, take: 10 }
    const res = await axios.get(url === "" ? getContactUsListUrl : url, { params: filterForm });
    console.log("getContactUsList res.data:::: ", res);

    if (res.data.success) {
      setContactUsListAll(res.data);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("getContactUsList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


//p   get all contact web    
export const getWebContactUsList = async (url = "", data, searchValue = "") => {

  const { setContactWebUsListAll, contactWebTakeList, setFilterContactWebActive } = useContactStore.getState();

  let filterForm = { ...data };
  if (filterForm?.status === "") delete filterForm.status;
  if (filterForm?.is_reply === null) delete filterForm.is_reply;
  if (filterForm?.is_seen === null) delete filterForm.is_seen;
  if (filterForm?.date === "") delete filterForm.date;
  if (filterForm?.custom_date === "") delete filterForm.custom_date;
  if (filterForm?.start_date === "") delete filterForm.start_date;
  if (filterForm?.end_date === "") delete filterForm.end_date;
  // return

  if (Object.keys(filterForm).length > 0) {
    setFilterContactWebActive(true);
    filterForm = { ...filterForm, take: contactWebTakeList.take };
  } else {
    setFilterContactWebActive(false);
    filterForm = { take: contactWebTakeList.take };
  }

  if (searchValue) filterForm = { ...filterForm, search: searchValue };

  try {
    if (searchValue) setLoadingSearch(true);
    else setLoading(true);

    console.log("BODY BEFORE AXIOS CALL::::", filterForm);
    const res = await axios.get(url === "" ? getWebContactUsListUrl : url, { params: filterForm });
    console.log("getWebContactUsList res.data:::: ", res.data);

    if (res.data.success) {
      setContactWebUsListAll(res.data);
      setLoading(false);
      setLoadingSearch(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      setLoadingSearch(false);
      return false;
    }

  } catch (error) {
    console.log("getWebContactUsList: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    setLoadingSearch(false);
    return false;
  }
};


// get contact details app    
export const getChatDetails = async (id) => {
  try {
    setLoading(true);
    const res = await axios.get(getChatDetailUrl, { params: { "id": id } });
    console.log("getChatDetails res.data:::: ", res);

    if (res.data.success) {
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("getChatDetails: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// send contact reply app
export const sendMessage = async (body) => {

  try {
    setLoading(true);
    const res = await axios.post(messageSendUrl, body);
    console.log("sendMessage res.data:::: ", res);

    if (res.data.success) {
      await getContactUsList(contactUsPageUrl?.url)
      await getDashboardData();
      await getDashboardPendingActivityData("invoice");

      //Toastr({ message: res.data.message, type: "success" });
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("sendMessage: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// send contact reply web
export const SendContactWebReply = async (body) => {
  const { contactWebPageUrl, filterFormWeb, contactWebSearch } = useContactStore.getState();
  try {
    setLoading(true);
    const res = await axios.post(sendContactWebReplyUrl, body);
    console.log("SendContactWebReply res.data:::: ", res);

    if (res.data.success) {
      await getWebContactUsList(contactWebPageUrl, filterFormWeb, contactWebSearch);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("SendContactWebReply: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};

// update seen contact message app
export const messageSeen = async (id, filterForm, contactUsPageUrl) => {
  try {
    setLoading(true);
    const res = await axios.post(messageSeenUrl, { "id": id });
    console.log("messageSeen res.data:::: ", res);

    if (res.data.success) {
      console.log("contactUsPageUrl", contactUsPageUrl)
      await getContactUsList(contactUsPageUrl, filterForm)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("messageSeen: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


// update seen contact message web
export const ContactWebSeen = async (id = 0) => {
  const { contactWebPageUrl, filterFormWeb, contactWebSearch } = useContactStore.getState();
  try {
    setLoading(true);
    const res = await axios.post(updateContactWebSeenUrl, { id: id, type: "guest" });
    console.log("ContactWebSeen res.data:::: ", res);

    if (res.data.success) {
      await getWebContactUsList(contactWebPageUrl, filterFormWeb, contactWebSearch);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("ContactWebSeen: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};


export const searchContactUsList = async (e, url = "") => {
  const { setLoadingSearch } = useUtilityStore.getState();
  const { resetContactUsFilterData, contactUsTakeList, contactUsSearch } = useContactStore.getState();
  try {
    setLoadingSearch(true);
    const res = await axios.get(url === "" ? getContactUsListUrl : url, { params: { search: contactUsSearch, take: contactUsTakeList.take } });
    console.log("searchContactUsList res.data:::: ", res.data);

    if (res.data.success) {
      resetContactUsFilterData()
      setFilterContactActive(false)
      setContactUsListAll(res.data);
      setLoadingSearch(false);
    } else {
      //Toastr({ message: res.data.message, type: "error" });
      setLoadingSearch(false);
      return false;
    }

  } catch (error) {
    console.log("searchContactUsList: ", error);
    //Toastr({ message: "An error occurred!", type: "error" });
    setLoadingSearch(false);
    return false;
  }
};




//App Mark a unread message
export const AppMarkUnseenMessage = async (id) => {

  const { contactUsPageUrl, filterForm } = useContactStore.getState();

  try {
    setLoading(true);
    const res = await axios.post(messageUnSeenUrl, { "id": id });
    console.log("webMarkUnreadMessage res.data:::: ", res);

    if (res.data.success) {
      console.log("contactUsPageUrl",contactUsPageUrl)
      Toastr({ message: res.data.message, type: "success" });
      await getContactUsList(contactUsPageUrl.url, filterForm)
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("webMarkUnreadMessage: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};



//web Mark a unread message
export const webMarkUnseenMessage = async (id) => {

  const { contactWebPageUrl, filterFormWeb, contactWebSearch } = useContactStore.getState();

  try {
    setLoading(true);
    const res = await axios.post(messageUnSeenUrl, { "id": id, "type": "guest" });
    console.log("webMarkUnreadMessage res.data:::: ", res);

    if (res.data.success) {
      console.log("contactWebPageUrl",contactWebPageUrl)
      Toastr({ message: res.data.message, type: "success" });
      await getWebContactUsList(contactWebPageUrl, filterFormWeb, contactWebSearch);
      setLoading(false);
      return true;
    } else {
      Toastr({ message: res.data.message, type: "error" });
      setLoading(false);
      return false;
    }

  } catch (error) {
    console.log("webMarkUnreadMessage: ", error);
    Toastr({ message: "An error occurred!", type: "error" });
    setLoading(false);
    return false;
  }
};
