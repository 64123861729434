import { Tooltip } from '@mui/material';
import React from 'react';
import { dateDiffCalendar, formatDate } from '../../../App/Utility/UtilityFunctions';
import { formatDateOrTime } from '../../../Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';
import useContactStore, { messageSeen } from './../../../App/Stores/ContactStore';
import { iUserAvatar } from './../../../App/Utility/source';

const ContactUsTableRow = ({ data, index }) => {

  const { contactUsPageUrl, filterForm, setShowContactMessageDetails } = useContactStore()

  const { setChatDetails } = useContactStore();

  const contactTableClick = async () => {
    // return LogSuccess("contact message data: ", data);
    await setShowContactMessageDetails(true);
    await setChatDetails(data);
    await messageSeen(data?.id, filterForm, contactUsPageUrl.url)
  }


  return (
    <>
      <tr onClick={() => { contactTableClick() }} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`}>
        <th className='px-2.5 font-normal text-center border-r py-s10'>
          {index}
        </th>


        <td className='border-r-[1px] py-s10 text-left px-s10 min-w-[190px] max-w-[190px] 2xl:min-w-[240px] 2xl:max-w-[240px] relative truncate'>
          {/* {data?.reply === null ? <span className='absolute left-1 top-1/2 w-3 h-3 rounded-full -translate-y-1/2 bg-cLessImportantText'></span> : ""} */}
          <span className='flex flex-row items-center'>
            <div className='min-w-[45px] max-w-[45px]'>
              <Image cursorPointerClass="cursor-pointer"
                className='rounded-full w-w44 h-h44 grow-0'
                src={data?.user?.image} dummyImage={iUserAvatar} />
            </div>
            <div className="items-center truncate font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12"
            >{data?.user?.name ?
              <div>
                <Tooltip title={data?.user?.name}>
                  <div className={`max-w-[130px] truncate
                  2xl:max-w-[200px] ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14 ' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.user?.name}</div>
                </Tooltip>

                <div className={`capitalize text-fs12 max-w-[150px] 2xl:max-w-[200px] truncate
                 ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14 ' : 'font-fw600 text-cBlack text-fs14'}`}
                >   {data?.user?.email ? <Tooltip title={data?.user?.email}><span>{data?.user?.email}</span></Tooltip> : ''}
                </div>
              </div>
              : <CommonEmptyStatus />}
            </div>
          </span>
        </td>

        {/* truncate pl-s10 ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14 ' : 'font-fw600 text-cBlack text-fs14 */}
        {/*e: subject details */}
        <td className='border-r-[1px] p-s10 text-fs12 text-center text-cTextBody min-w-[180px] max-w-[180px] 2xl:min-w-[250px] 
        2xl:max-w-[250px]'>
          <Tooltip title={data?.subject}>
            <div className={`truncate ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>
              {data?.subject ? data?.subject : 'N/A'}</div>
          </Tooltip>
        </td>

        {/*l     message details  */}
        <td className='border-r-[1px] p-s10 text-fs14 font-fw400 text-center text-cTextBody min-w-[170px] max-w-[170px] 2xl:min-w-[220px] 
        2xl:max-w-[220px]'>
          <Tooltip title={data?.message}>
            <div className={`truncate ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.message}</div>
          </Tooltip>
        </td>

        {/* user type */}
        <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center text-cTextBody min-w-[100px]'>
          <div className={` capitalize ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.user?.role ? data?.user?.role : 'NA'}</div>
        </td>

        {/*e    date and time */}
        <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center min-w-[120px] text-cTextBody'>
          <span className={`${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.created_at ?
            <><span>{formatDate(data?.created_at)}</span><div className='pt-s5'>{formatDateOrTime(data?.created_at)}</div></>
            : 'N/A'}</span>
        </td>

        {/* l     last action */}
        <td className='p-s10 border-r-[1px] text-center  min-w-[140px] max-w-[140px]'>
          <span className={`${data?.is_seen ? 'font-fw400 text-cImportantText text-fs12' : 'font-fw600 text-cBlack text-fs12'}`}>
            {
              dateDiffCalendar(new Date(data?.updated_at), new Date(), true) === '0 day ' ? "Today" :
                dateDiffCalendar(new Date(data?.updated_at), new Date(), true) + " ago"
            }
          </span>
        </td>
      </tr>
    </>
  )
}

export default ContactUsTableRow;