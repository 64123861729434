import React, { useEffect, useState } from 'react';
import useInstructorStore, { getInstructorToggle } from '../../App/Stores/InstructorStore';
import { iEdiItIcon } from '../../App/Utility/source';
import { dateDiffCalendar,  roughLicenseDurationFormatter } from '../../App/Utility/UtilityFunctions';
import LessonsCard from '../../Components/CommonDetailPage/LessonsCard';
import NoLicense from '../../Components/CommonDetailPage/NoLicense';
import CommonEmptyStatus from '../../Components/CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Components/Image/Image';
import GreenSwitch from '../../Components/Switch/GreenSwitch';
import { formatDate } from '../../Utility/UtilityFunctions';

function InstructorBasicDetails({ data }) {

    const [enabled, setEnabled] = useState(false);

    const [licenseDetails, setLicenseDetails] = useState({});

    const { setEditFormData,setInstructorActivateID, setShowDeactivateModal, setShowEditModal } = useInstructorStore();

    let MOMS = (JSON.parse(localStorage.getItem("user")));

    const HandleActiveInstructor = () => {
        setInstructorActivateID(data?.id);

        if (enabled) {
            setShowDeactivateModal(true);
        } else {
            getInstructorToggle();
        }

    }

    useEffect(() => {
        if (data?.is_active) setEnabled(true);
        else setEnabled(false);

        if (!MOMS?.moms) MOMS = { moms: 25 };

        setLicenseDetails({
            title: data?.lisence?.lisence?.title,
            price: data?.lisence?.lisence?.price,
            packageDuration: roughLicenseDurationFormatter(data?.lisence?.lisence?.duration),
            momsValue: (parseInt(data?.lisence?.lisence?.price) * ((MOMS.moms) / 100)),
            startDate: formatDate(data?.lisence?.start_time),
            endDate: formatDate(data?.applyLicense_end_time),
            purchaseDuration: dateDiffCalendar(data?.lisence?.start_time ?? new Date(), data?.lisence?.end_time),
            licenseDetails: data?.lisence?.lisence?.details,
        })

    }, [data]);

    //console.log("data",data)

  const reSetData = () => {
    setEditFormData("id","");
    setEditFormData("instructor_name", "");
    setEditFormData("email", "")
    setEditFormData("phone_number", '')
    setEditFormData("address", "")
    setEditFormData("image", "")
  }

//   console.log("datttttt",data)
    return (
        <div className="flex w-full">
            <div className="w-full pr-5">
                <div className="bg-cBackgroundAndCategory min-h-[350px] rounded-br10 py-s20 px-s20">
                    <div className='flex justify-between lg:flex'>
                        <div className="flex w-full">
                            <div className=''>
                                <Image className='rounded-full w-w88 h-h88 grow-0 mb-s18' src={data?.profile_photo} />

                                <div className='flex justify-center'>
                                    <GreenSwitch
                                        enabled={enabled} setEnabled={() => { HandleActiveInstructor() }}
                                    />
                                </div>
                                <div className={`text-center ${enabled ? 'text-cPassed' : 'text-cFailed'} mt-s5 text-fs14 font-fw400`}>
                                    {data?.is_active ? 'Active' : 'Deactivate'} </div>

                            </div>

                            <div className="ml-s15">
                                <div onClick={() => { console.log(data, MOMS); }} className="capitalize leading-9 font-fw600 text-fs24 text-cHighlighted mb-s2">
                                    {data?.name === "null" || data?.name === null ?
                                        <CommonEmptyStatus leading='leading-9' size="text-fs24" fontWeight="font-fw600" textColor='text-cHighlighted' /> : data?.name}
                                </div>
                                <div className="leading-3 font-fw500 text-fs12 text-cImportantText mb-s6">
                                    Email : {data?.email === "null" || data?.email === null ?
                                        <CommonEmptyStatus leading='leading-3' size="text-fs12" fontWeight="font-fw500" textColor='text-cImportantText' /> : data?.email}
                                </div>
                                <div className="leading-3 font-fw500 text-fs12 text-cImportantText pb-s6">
                                    Phone : {data?.phone_number === "null" || data?.phone_number === null ?
                                        <CommonEmptyStatus leading='leading-3' size="text-fs12" fontWeight="font-fw500" textColor='text-cImportantText' /> : data?.phone_number}</div>
                                <div className="capitalize leading-3 font-fw500 text-fs12 text-cImportantText pb-s6">
                                    Address : {data?.address === "null" || data?.address === null ?
                                        <CommonEmptyStatus leading='leading-3' size="text-fs12" fontWeight="font-fw500" textColor='text-cImportantText' /> : data?.address}</div>
                                <div className="leading-3 font-fw500 text-fs12 text-cImportantText mb-s6">
                                    joined : {data?.joined_date === "null" || data?.joined_date === null ?
                                        <CommonEmptyStatus leading='leading-3' size="text-fs12" fontWeight="font-fw500" textColor='text-cImportantText' /> : formatDate(data?.joined_date)}</div>

                            </div>
                        </div>
                        <div className="cursor-pointer"><img className="w-s28 h-s28" src={iEdiItIcon}
                        onClick={async() => { 
                            await reSetData()
                            setShowEditModal(true)
                             }} alt="" /></div>
                    </div>
                </div>
            </div>

            {
                data?.applyLicense_id === null ? <NoLicense user_id={data?.user_id} showAssignLicense={data?.is_active} /> :
                    <div className='min-w-[400px]'>
                        <LessonsCard
                            licenseOf="instructor"
                            withEditOption={true}
                            title={licenseDetails?.title}
                            price={licenseDetails?.price}
                            packageDuration={licenseDetails?.packageDuration}
                            momsValue={licenseDetails?.momsValue}
                            startDate={licenseDetails?.startDate}
                            endDate={licenseDetails?.endDate}
                            purchaseDuration={licenseDetails?.purchaseDuration}
                            licenseDetails={licenseDetails?.licenseDetails}
                            status={data?.lisence?.status}
                            licenseEdit={true}
                        />
                    </div>
            }
        </div>

    )
}

export default InstructorBasicDetails
