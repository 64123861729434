import React, { useState } from "react";
import DraggableList from "../../Components/DraggableLists/DraggableList";

const Test = () => {


  return (
    <>
      <div
        onClick={() => { console.log("mainObject"); }}
        className="flex flex-col justify-center items-center py-10 w-full h-[15vh] text-4xl font-thin shadow-lg text-cTextBlack bg-slate-300 rounded-br10">
        TEST - PAGE
      </div>

      <div className="px-10 py-10 h-[85vh] bg-cyan-400">
        <DraggableList />
      </div>

    </>
  );
};

export default Test;

