import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import useInvoiceStore, { getInstructorInvoiceIndex } from '../../App/Stores/InvoiceStore';
import useSchoolStore, { getSchoolList, searchSchoolList } from '../../App/Stores/SchoolStore';
import CommonButton from '../../Components/Button/CommonButton';
import CommonInput from '../../Components/Input/CommonInput';
import SelectInput from '../../Components/Input/SelectInput';
import CommonModal from '../../Components/Modal/CommonModal';
import AsyncSingleCombobox from '../../Components/Select/AsyncSingleCombobox';
import { Toastr } from '../../Utility/UtilityFunctions';

function InstructorInvoiceFilter() {
    const {
        showInstructorInvoiceFilterModal,
        setShowInstructorInvoiceFilterModal,
        filterInstructorInvoiceData,
        setFilterInstructorInvoiceData,
        resetFilterInstructorInvoiceData,
        instructorID,
        instructorInvoiceType,
        instructorInvoiceSearchKey,
        invoiceFilterInsSel,
        setInvoiceFilterInsSel
    } = useInvoiceStore();

    const { schoolSearchKey, setSchoolSearchKey, schoolListAll } = useSchoolStore();

    const [dataArray, setDataArray] = useState([]);

    const [searchValueSchool] = useDebounce(schoolSearchKey, 500);

    useEffect(() => {
        SmartSearchSchool();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValueSchool]);

    const SmartSearchSchool = async () => {
        if (searchValueSchool) {
            await searchSchoolList(searchValueSchool, "", 500);
        } else {
            await getSchoolList();
        }

    }

    useEffect(() => {
        let t_array = []
        if (schoolListAll?.data?.length) {
            schoolListAll?.data?.map((item) => t_array.push({ label: item?.name, value: item?.id }));
            setDataArray(t_array);
        }
    }, [schoolListAll?.data]);

    return (
        <div>
            <CommonModal
                showModal={showInstructorInvoiceFilterModal}
                setShowModal={setShowInstructorInvoiceFilterModal}
                modalTitle="Filter Instructor Invoice"
                mainContent={
                    <>
                        <form onSubmit={(e) => e.preventDefault()}>

                            {/* g:          payment status */}
                            <div className="my-s20">
                                <SelectInput
                                    label="Payment Status"
                                    placeholder="Choose Payment Status"
                                    selectOptionOnChange={(e) => {
                                        if (e === "paid") setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, status: ["paid1", "paid2"] });
                                        else if (e === "missing") setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, status: ["missing1", "missing2"] });
                                        else if (e === '') setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, status: [] });
                                        else setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, status: [e] });
                                    }}

                                    dataArray={[
                                        {
                                            title: "accepted",
                                            value: localStorage.getItem("instructorInvoiceType") === "history" ? "accepted" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "accepted" ? true : false
                                        },
                                        {
                                            title: "requested",
                                            value: localStorage.getItem("instructorInvoiceType") !== "history" ? "requested" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "requested" ? true : false
                                        },
                                        {
                                            title: "cancelled",
                                            value: localStorage.getItem("instructorInvoiceType") === "history" ? "cancelled" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "cancelled" ? true : false
                                        },
                                        {
                                            title: "created",
                                            value: localStorage.getItem("instructorInvoiceType") !== "history" ? "created" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "created" ? true : false
                                        },
                                        {
                                            title: "paid",
                                            value: localStorage.getItem("instructorInvoiceType") !== "history" ? "paid" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "paid1" ? true : false
                                        },
                                        {
                                            title: "missing payment",
                                            value: localStorage.getItem("instructorInvoiceType") !== "history" ? "missing" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "missing1" ? true : false
                                        },

                                        {
                                            title: "rejected",
                                            value: localStorage.getItem("instructorInvoiceType") === "history" ? "rejected" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "rejected" ? true : false
                                        },
                                        {
                                            title: "expired",
                                            value: localStorage.getItem("instructorInvoiceType") === "history" ? "expired" : "",
                                            selected: filterInstructorInvoiceData?.status[0] === "expired" ? true : false
                                        }
                                    ]}
                                />
                            </div>

                            {/* y           school based filter */}
                            {localStorage.getItem("instructorInvoiceType") === "school_instructor" ?
                                <div className="my-s20">
                                    <AsyncSingleCombobox
                                        label="filter by school"
                                        placeholder={invoiceFilterInsSel === "" ? 'Select or search a school' : invoiceFilterInsSel}
                                        onSearchItem={(value) => setSchoolSearchKey(value)}
                                        optionArray={dataArray}
                                        selectedValue={{ label: invoiceFilterInsSel, id: filterInstructorInvoiceData?.school_id }}
                                        onChangeLabel={(label) => {
                                            setInvoiceFilterInsSel(label);
                                            console.log("SELECTED INS-LABEL: ", label);
                                        }}
                                        onChange={(value) => {
                                            console.log("SELECTED INS-ID: ", value);
                                            setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, school_id: value });
                                        }}
                                    />
                                </div> : ""}

                            {/*b         start date and date */}
                            <div className='flex justify-between items-center w-full'>
                                <div className='p-0 rounded-full'>
                                    <CommonInput
                                        type='date'
                                        value={filterInstructorInvoiceData?.start_date}
                                        startDate={"1901-01-01"}
                                        label="start date"
                                        allowPastDates={true}
                                        onChange={(e) => {
                                            // console.log((e.target.value));
                                            const startDate = new Date(e.target.value);
                                            const endDate = new Date(filterInstructorInvoiceData?.end_date);

                                            // console.log("startDate::::", startDate, "; endDate::::", endDate);

                                            if (startDate > endDate) {
                                                setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, end_date: "", start_date: e.target.value });
                                            } else {
                                                setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, start_date: e.target.value });
                                            }
                                            // setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, start_date: e.target.value });
                                        }}
                                    />
                                </div>

                                <div className='flex items-center'>
                                    <div className='pr-0'>
                                        <CommonInput
                                            // required={filterInstructorInvoiceData?.start_date ? true : false}
                                            type='date'
                                            label="end date"
                                            value={filterInstructorInvoiceData?.end_date}
                                            // disabled={!filterInstructorInvoiceData?.start_date}
                                            startDate={filterInstructorInvoiceData?.start_date ? filterInstructorInvoiceData?.start_date : "1901-01-01"}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setFilterInstructorInvoiceData({ ...filterInstructorInvoiceData, end_date: (e.target.value) });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/*r         action buttons  */}
                            <div className='flex justify-between mt-s20'>
                                <CommonButton onClick={() => {
                                    resetFilterInstructorInvoiceData();
                                }}
                                    fullRounded={true}
                                    btnLabel='Clear'
                                    colorType="FilterClearButton" text="fs16" />
                                <CommonButton
                                    onClick={async () => {
                                        console.log(filterInstructorInvoiceData);
                                        // return
                                        // if (filterInstructorInvoiceData?.start_date) {
                                        //     if (!filterInstructorInvoiceData?.end_date) {
                                        //         return;
                                        //     }
                                        // }

                                        localStorage.setItem("instructorInvoicePaginationURL", "");

                                        let filterSuccess = await getInstructorInvoiceIndex(instructorID, instructorInvoiceType, "", instructorInvoiceSearchKey, filterInstructorInvoiceData);
                                        if (filterSuccess) {
                                            Toastr({ message: "Your filter successfully Apply", type: "success" });
                                            setShowInstructorInvoiceFilterModal(false);
                                        }
                                    }}
                                    type="submit"
                                    btnLabel="Apply"
                                    colorType="primary"
                                    roundedFull="true"
                                />
                            </div>

                        </form>

                    </>
                }

            />
        </div>
    );
};


export default InstructorInvoiceFilter