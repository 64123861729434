import { Tooltip } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useInvoiceStore from '../../../App/Stores/InvoiceStore';
import { iImageIogo, iRightArrow } from '../../../App/Utility/source';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from '../../Image/Image';


const StudentTableRow = ({ data, index }) => {

  const navigate = useNavigate();

  const location = useLocation();

  const { id } = useParams();

  const { setStudentInvoiceSearchKey } = useInvoiceStore();

  const Click = () => {
    setStudentInvoiceSearchKey("")
    if (location.pathname === `/category/student/${id}`) {
      navigate("/category/student/profile")
    }
    else {
      navigate(`/student/details/${data?.id}`)
    }
  }

  // console.log("data",data)

  return (
    <>
      <tr onClick={Click} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`} >

        <th className='font-normal text-center border-r p-s10'>
          {index}
        </th>

        {/* name with image */}
        <td className='border-r-[1px] p-s10 text-left px-s10 min-w-[150px] max-w-[150px] 2xl:min-w-[200px] 2xl:max-w-[200px]'>
          <div className='flex flex-row items-center'>
            <div className='min-w-[50px] max-w-[50px]'>
              <Image cursorPointerClass="cursor-pointer" className='rounded-full w-w44 h-h44 grow-0' src={data?.profile_photo} />
            </div>
            <Tooltip title={data?.name}>
              {data?.name ? <div className='capitalize font-semibold max-w-[130px] 2xl:max-w-[200px] truncate'>{data?.name}</div> : <CommonEmptyStatus />}
            </Tooltip>
          </div>
        </td>

        {/* email address */}
        <td className='p-s10  border-r-[1px] text-fs14 text-center text-cTextBody min-w-[150px] max-w-[150px]
        2xl:min-w-[200px] 2xl:max-w-[200px]'>
          <Tooltip title={data?.email}>
            <div className='font-fw400 text-cImportantText text-fs14 truncate'>{data?.email ? data?.email : <CommonEmptyStatus />}</div>
          </Tooltip>
        </td>

        {/* phone number */}
        <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center  text-cTextBody min-w-[110px] max-w-[110px] truncate'>

          <span className='font-fw400 text-cImportantText text-fs14 truncate'>
            {data?.phone_number === "null" || data?.phone_number === null ? <CommonEmptyStatus /> : <Tooltip title={data?.phone_number}><span>{data?.phone_number}</span></Tooltip>}
          </span>
        </td>

        {/* curriculum */}
        <td className='p-s10 border-r-[1px] text-center min-w-[100px]'>
          {data?.curriculum ? data?.curriculum[0]?.category_name ?
            <div className='flex justify-center items-center space-x-2.5 font-fw600'>
              <div className='rounded-full w-w44 h-h44'>
                <Image className='object-contain p-2' src={data?.curriculum[0]?.category_icon} dummyImage={iImageIogo} />
              </div>
              <div className='justify-center ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12'>
                {data?.curriculum[0]?.category_name ?? <CommonEmptyStatus />}
              </div>
            </div> : <CommonEmptyStatus /> : ""}

        </td>

        {/* status of the account */}
        <td className='pl-s10 py-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[100px]'>
          <div className='flex justify-center items-center space-x-2.5'>
            <div className={`font-fw600 text-fs14 ${data?.is_active ? "text-cPassed" : "text-cCancelled"}`}>{data?.is_active ? "Active" : "Deactivate"}</div>
            <img className="mt-s5" src={iRightArrow} alt=""></img>
          </div>
        </td>
      </tr>
    </>
  )
}

export default StudentTableRow;