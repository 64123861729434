import React, { useState } from 'react'
import CommonModal from './../../../Components/Modal/CommonModal';
import CommonInput from './../../../Components/Input/CommonInput';
import CommonButton from './../../../Components/Button/CommonButton';
import useContactStore, { AppMarkUnseenMessage, sendMessage } from '../../../App/Stores/ContactStore';
import { iUserAvatar } from '../../../App/Utility/source';
import { formatDate } from './../../../App/Utility/UtilityFunctions';
import { useNavigate } from 'react-router-dom';
import Image from './../../../Components/Image/Image';

function ContactUsChatModal() {

    const { showContactMessageDetails, setShowContactMessageDetails, chatDetails,contactUsPageUrl } = useContactStore();

    const [reply, setReply] = useState();

    const navigate = useNavigate();

    return (
        <div>
            <CommonModal
                showModal={showContactMessageDetails}
                setShowModal={setShowContactMessageDetails}
                modalTitle="Message Details"
                mainContent={
                    <>
                        <div className='mt-s20'>
                            <div className="flex justify-between bg-cBackgroundAndCategory rounded-br10 h-h75 sm:h-h65 lg:h-h65">
                                <div className="flex col-span-9 md:col-span-9 px-s10">

                                    <div className='rounded-full w-s44 flex items-center'>
                                        <Image className='rounded-full w-w44 h-h44 grow-0' src={chatDetails?.user?.image} dummyImage={iUserAvatar} />
                                    </div>

                                    <div className="ml-s10 flex items-center">
                                        <div>
                                            <div className="leading-3 font-fw600 text-fs14 text-cMainBlack pb-s5">{chatDetails?.user?.name ? chatDetails?.user?.name : chatDetails?.name}</div>
                                            <div className="leading-3 font-fw500 text-fs12 text-cImportantText">{chatDetails?.user?.email ? chatDetails?.user?.email : chatDetails?.email}</div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    onClick={() => {
                                        navigate(`/contact-us${chatDetails?.user?.role === "student" ? "/student" : chatDetails?.user?.role === "school" ? "/school" : chatDetails?.user?.role === "instructor" ? "/instructor" : ""}-profile/details/${chatDetails?.user?.user_table_id}`);
                                        setShowContactMessageDetails(false)
                                    }}
                                    className="cursor-pointer flex items-center mr-s20">
                                    <div className="rounded-full bg-cBrandColor w-s105 h-h34 text-cBrandColor2 py-s7 pl-s16 text-fs14 font-fw500">
                                        View Profile
                                    </div>

                                </div>
                            </div>

                            <div>
                                <div className="mt-s20"><span className="text-fs14 font-fw600 text-cHighlighted">Subject</span>
                                    <span className="text-fs14 font-fw400 text-cHighlightedTexts"> : {chatDetails?.subject}</span>
                                </div>

                                <div>
                                    <div className='flex justify-between mt-s20 '>
                                        <div className="text-fs14 font-fw600 text-cHighlighted">Message details</div>
                                        <div className='text-fs12 font-fw400 text-cIconColor2'>
                                            {chatDetails?.created_at ? formatDate(chatDetails?.created_at) : ''}
                                        </div>
                                    </div>
                                    <div className="text-fs14 font-fw400 text-cTextGray mb-s20">{chatDetails?.message}</div>
                                </div>

                                {!chatDetails?.reply && <div className="px-1 pb-s20">
                                    <hr />
                                </div>}

                                <form onSubmit={async (e) => {
                                    e.preventDefault()
                                    let data = { id: chatDetails?.id, reply: reply }
                                    const value = await sendMessage(data);
                                    if (value) {
                                        setReply("");
                                        setShowContactMessageDetails(false);
                                    }
                                }}>
                                    {/* {!chatDetails?.reply && <CommonInput required={true} value={reply} onChange={(e) => { setReply(e.target.value) }} label="Reply" type="text" textarea="true" />}
                                    {chatDetails?.reply &&
                                        <div>
                                            <div className='flex justify-between mt-s20'>
                                                <div className="text-fs14 font-fw600 text-cHighlighted">Reply details</div>
                                                <div className='text-fs12 font-fw400 text-cIconColor2'> {formatDate(chatDetails?.updated_at ? chatDetails?.updated_at : chatDetails?.create_date)}</div>
                                            </div>
                                            <div className="text-fs14 font-fw400 text-cTextGray">{chatDetails?.reply}</div>
                                        </div>
                                    }
                                    {!chatDetails?.reply && <div className='flex justify-center mt-s20'>
                                        <CommonButton
                                            type="submit"
                                            btnLabel="Send Reply"
                                            colorType="primary"
                                            roundedFull="true"
                                        />
                                    </div>} */}

                                    <div className='flex justify-center'>
                                        <CommonButton onClick={() => {
                                            const success = AppMarkUnseenMessage(chatDetails?.id,contactUsPageUrl)
                                            if (success) { setShowContactMessageDetails(false) }
                                        }} btnLabel='Mark Unseen' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                }

            />
        </div>
    )
}

export default ContactUsChatModal
