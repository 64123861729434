import React, { useState } from 'react';
import useContactStore, { SendContactWebReply, webMarkUnseenMessage } from '../../../../App/Stores/ContactStore';
import { iUserAvatar } from '../../../../App/Utility/source';
import { formatDate } from '../../../../App/Utility/UtilityFunctions';
import CommonButton from '../../../../Components/Button/CommonButton';
import Image from '../../../../Components/Image/Image';
import CommonInput from '../../../../Components/Input/CommonInput';
import CommonModal from '../../../../Components/Modal/CommonModal';

const ContactDetailsModal = () => {
    const [reply, setReply] = useState("");

    const {
        showContactWebMessageDetails,
        setShowContactWebMessageDetails,
        contactWebDetails,
    } = useContactStore();

    
    return (
        <div>
            <CommonModal
                showModal={showContactWebMessageDetails}
                setShowModal={setShowContactWebMessageDetails}
                modalTitle="Message Details web"
                mainContent={
                    <>
                        <div className='mt-s20'>
                            <div className="flex justify-between bg-cBackgroundAndCategory rounded-br10 h-h75 sm:h-h65 lg:h-h65">
                                <div className="flex col-span-9 md:col-span-9 py-s12 px-s16">

                                    <div className='rounded-full w-s44 bg-cBrandColor2'>
                                        <Image className='rounded-full w-w44 h-h44 grow-0' src={contactWebDetails?.user?.image} dummyImage={iUserAvatar} />
                                    </div>

                                    <div className="ml-s12 py-s10">
                                        <div className="capitalize leading-3 font-fw600 text-fs14 text-cMainBlack pb-s4">{contactWebDetails?.user?.name ? contactWebDetails?.user?.name : contactWebDetails?.name}</div>
                                        <div className="leading-3 font-fw500 text-fs12 text-cImportantText">{contactWebDetails?.user?.email ? contactWebDetails?.user?.email : contactWebDetails?.email}</div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="mt-s20"><span className="text-fs14 font-fw600 text-cHighlighted">Subject</span>
                                    <span className="text-fs14 font-fw400 text-cHighlightedTexts"> : {contactWebDetails?.subject}</span>
                                </div>

                                <div>
                                    <div className='flex justify-between mt-s20 '>
                                        <div className="text-fs14 font-fw600 text-cHighlighted">Message details</div>
                                        <div className='text-fs12 font-fw400 text-cIconColor2'>
                                            {contactWebDetails?.created_at ? formatDate(contactWebDetails?.created_at) : ''}
                                        </div>
                                    </div>
                                    <div className="text-fs14 font-fw400 text-cTextGray mb-s20">{contactWebDetails?.message}</div>
                                </div>

                                {!contactWebDetails?.reply && <div className="px-1 pb-s20">
                                    <hr />
                                </div>}

                                <form onSubmit={async (e) => {
                                    e.preventDefault()
                                    if (reply === "") return;

                                    let data = { id: contactWebDetails?.id, reply: reply }
                                    const replySuccess = await SendContactWebReply(data);
                                    if (replySuccess) {
                                        setReply("");
                                        setShowContactWebMessageDetails(false);
                                    }
                                }}>
                                    {/* {!contactWebDetails?.reply && <CommonInput required={true} value={reply} onChange={(e) => { setReply(e.target.value) }} label="Reply" type="text" textarea="true" />}
                                    {contactWebDetails?.reply &&
                                        <div>
                                            <div className='flex justify-between mt-s20'>
                                                <div className="text-fs14 font-fw600 text-cHighlighted">Reply details</div>
                                                <div className='text-fs12 font-fw400 text-cIconColor2'> {formatDate(contactWebDetails?.updated_at ? contactWebDetails?.updated_at : contactWebDetails?.create_date)}</div>
                                            </div>
                                            <div className="text-fs14 font-fw400 text-cTextGray">{contactWebDetails?.reply}</div>
                                        </div>
                                    }
                                    {!contactWebDetails?.reply && <div className='flex justify-center mt-s20'>
                                        <CommonButton
                                            type="submit"
                                            btnLabel="Send Reply"
                                            colorType="primary"
                                            roundedFull="true"
                                        />
                                    </div>} */}

                                    <div className='flex justify-center'>
                                        <CommonButton onClick={() => {
                                            const success=webMarkUnseenMessage(contactWebDetails?.id)
                                            if(success){setShowContactWebMessageDetails(false)}
                                        }} btnLabel='Mark Unseen' />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                }

            />
        </div>
    )
}

export default ContactDetailsModal