import { Tooltip } from '@mui/material'
import React from 'react'
import useContactStore, { ContactWebSeen } from '../../../App/Stores/ContactStore'
import { iUserAvatar } from '../../../App/Utility/source'
import { dateDiffCalendar, formatDate, formatDateOrTime } from '../../../App/Utility/UtilityFunctions'
import CommonEmptyStatus from '../../../Components/CommonEmptyStatus/CommonEmptyStatus'
import Image from '../../../Components/Image/Image'

const ContactWebTableRow = ({ data, index }) => {
    const { setContactWebDetails, setShowContactWebMessageDetails } = useContactStore();
    const contactTableClick = async () => {
        // set the current contact message as details object        
        await setContactWebDetails(data);
        await setShowContactWebMessageDetails(true);
        if (data?.is_seen === 0) await ContactWebSeen(data?.id);
    }
    return (
        <>
            <tr onClick={() => { contactTableClick() }} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView`}>
                <th className='font-normal text-center border-r p-s10'>
                    {index}
                </th>
                {/* ${data?.reply === null ? "pl-3" : "pl-3"} */}
                {/* name and email with image */}
                <td className='border-r-[1px] py-s10 text-left px-s10 min-w-[190px] max-w-[190px] 2xl:min-w-[240px] 2xl:max-w-[240px] relative truncate'>
                    {/* {data?.reply === null ? <span className='absolute left-1 top-1/2 w-3 h-3 rounded-full -translate-y-1/2 bg-cLessImportantText'></span> : ""} */}
                    <span className='flex flex-row items-center'>
                        <div className='min-w-[45px] max-w-[45px]'>
                            <Image cursorPointerClass="cursor-pointer"
                                className='rounded-full w-w44 h-h44 grow-0'
                                src={data?.image} dummyImage={iUserAvatar} />
                        </div>
                        <div className="items-center truncate font-fw600 ml-s10 text-cMainBlack lg:text-fs14 sm:text-fs12"
                        >{data?.name ?
                            <div>
                                <Tooltip title={data?.name}>
                                    <div className={`max-w-[130px] truncate
                  2xl:max-w-[200px] ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14 ' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.name}</div>
                                </Tooltip>

                                <div className={`capitalize text-fs12 max-w-[150px] 2xl:max-w-[200px] truncate
                 ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14 ' : 'font-fw600 text-cBlack text-fs14'}`}
                                >   {data?.email ? <Tooltip title={data?.email}><span>{data?.email}</span></Tooltip> : ''}
                                </div>
                            </div>
                            : <CommonEmptyStatus />}
                        </div>
                    </span>
                </td>

                {/* phone number */}
                <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center text-cTextBody truncate min-w-[100px] max-w-[100px] 2xl:min-w-[120px] 
                 2xl:max-w-[120px]'>
                    <span className={` capitalize ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.phone ? data?.phone : 'N/A'}</span>
                </td>

                {/*e: subject details */}
                <td className='border-r-[1px] p-s10 text-fs12 text-center text-cTextBody min-w-[160px] max-w-[160px] 2xl:min-w-[200px] 
                 2xl:max-w-[200px]'>
                    <Tooltip title={data?.subject}>
                        <div className={`truncate ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.subject ? data?.subject : 'N/A'}</div>
                    </Tooltip>
                </td>

                {/*l     message details  */}
                <td className='border-r-[1px] p-s10 text-fs14 font-fw400 text-center text-cTextBody min-w-[170px] max-w-[170px] 2xl:min-w-[220px] 
                   2xl:max-w-[220px]'>
                    <Tooltip title={data?.message}>
                        <div className={`truncate ${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.message}</div>
                    </Tooltip>
                </td>

                {/*e    date and time */}
                <td className='p-s10 border-r-[1px] text-fs14 font-fw400 text-center min-w-[120px] text-cTextBody '>
                    <span className={`${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>{data?.created_at ?
                        <><span>{formatDate(data?.created_at)}</span><div className='pt-s5'>{formatDateOrTime(data?.created_at)}</div></>
                        : 'N/A'}</span>
                </td>

                {/* l     last action */}
                <td className='p-s10 border-r-[1px] text-center in-w-[150px] max-w-[150px]'>
                    <span className={`${data?.is_seen ? 'font-fw400 text-cImportantText text-fs14' : 'font-fw600 text-cBlack text-fs14'}`}>
                        {
                            dateDiffCalendar(new Date(data?.updated_at), new Date(), true) === '0 day ' ? "Today" :
                                dateDiffCalendar(new Date(data?.updated_at), new Date(), true) + " ago"
                        }
                    </span>
                </td>
            </tr>
        </>
    )
}

export default ContactWebTableRow