import { Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Clamp from 'react-multiline-clamp';
import { useNavigate } from 'react-router-dom';
import useInvoiceStore from '../../../App/Stores/InvoiceStore';
import { iRightArrow } from '../../../App/Utility/source';
import { dateDiffCalendar } from '../../../App/Utility/UtilityFunctions';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import Image from './../../Image/Image';

const InstructorTableRow = ({ data, index }) => {

  const navigate = useNavigate();
  const [expertiseArea, setExpertiseArea] = useState([]);

  const { setInstructorInvoiceSearchKey } = useInvoiceStore();

  const VerifyExpertise = () => {
    let exp_array = [];
    if (data?.expertise?.length > 0) data.expertise?.map((item) => {
      if (item?.classroom === 0 && item?.driving === 0 && item?.external === 0) return null;
      else exp_array.push(item?.category_name);
      // exp_array.push(item?.category_name);
      return null;
    });
    setExpertiseArea(exp_array);
    // console.log("EXP AREA: ", exp_array);
  }

  useEffect(() => VerifyExpertise(), [data]);

  return (
    <>
      <tr onClick={() => {
        // return console.log(data);
        navigate("/instructor/details/" + data?.id);
        setInstructorInvoiceSearchKey("")
      }} className={`border-b border-collapse cursor-pointer border-cNmSelect hover:bg-cGridView m-s10`} >

        <th className='font-normal text-center border-r p-s10'>
          {index}
        </th>

        {/* name with image */}
        <td className='border-r-[1px] text-center p-s10 min-w-[160px] max-w-[160px] 2xl:min-w-[220px] 2xl:max-w-[220px]'>
          <div className='flex items-center'>
            <div className='min-w-[50px] max-w-[50px]'>
              <Image cursorPointerClass="cursor-pointer" className='w-w44 h-h44 rounded-full' src={data?.profile_photo} alt="" />
            </div>
            {data?.name === "null" || data?.name === null ?
              <CommonEmptyStatus /> :
              <Tooltip title={data?.name}>
                <span className='max-w-[130px] 2xl:max-w-[200px] truncate font-semibold'>{data?.name}</span>
              </Tooltip>
            }
          </div>
        </td>

        {/* email address */}
        <td className='py-s10 border-r-[1px] p-s10 text-fs14 text-center text-cTextBody  min-w-[140px] max-w-[140px]
        2xl:min-w-[200px] 2xl:max-w-[200px]'>
          <Tooltip title={data?.email}>
            <div className='truncate font-fw400 text-cImportantText text-fs14'>{data?.email ? data?.email :
              <CommonEmptyStatus />}
            </div>
          </Tooltip>
        </td>

        {/* phone number */}
        <td className='py-s10 border-r-[1px] p-s10 text-fs14 text-center text-cTextBody min-w-[80px] max-w-[80px] truncate'>
          <span className='truncate font-fw400 text-cImportantText text-fs14'>
          {data?.phone_number === "null" || data?.phone_number === null ? <CommonEmptyStatus /> :
           <Tooltip title={data?.phone_number}><span>{data?.phone_number}</span></Tooltip>}</span>
        </td>

        {/* expertise area */}
        <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody min-w-[150px] max-w-[150px]
                truncate'>
          <Tooltip title={
            expertiseArea?.length > 3 ? expertiseArea?.map((item, indexExp) => (indexExp + 1 < expertiseArea?.length) ? item + ", " : item + "") : ""
          } >
            <span className='text-center font-fw400 text-cImportantText text-fs14 flex justify-center items-center space-x-2.5'>
              {
                expertiseArea?.length > 0 ?
                  expertiseArea?.length > 3 ?
                    expertiseArea?.map((item, indexCat) => (
                      indexCat < 3 ? <div key={indexCat} className='truncate border-[2px] border-cBrandColor w-[30px] h-[30px] rounded-full text-fs12 font-semibold flex items-center justify-center text-cBrandColor'>
                        {item}
                      </div> : ""
                    ))
                    : expertiseArea?.map((item, indexCat) => (
                      <div key={indexCat} className='truncate border-[2px] border-cBrandColor w-[30px] h-[30px] rounded-full text-fs12 font-semibold flex items-center justify-center text-cBrandColor'>
                        {item}
                      </div>
                    ))

                  : 'NA'
              }
              {expertiseArea?.length > 3 ?
                <span className='font-semibold rounded-full flex items-center justify-center w-[30px] h-[30px] bg-cBrandColor text-fs12 text-white'>{"+" + (expertiseArea?.length - 3)}</span>
                : ""}
            </span>
          </Tooltip>
        </td>

        {/* license info */}
        <td className='py-s10 border-r-[1px] px-s5 text-center min-w-[160px] max-w-[160px]'>
          <div>
            <span className='text-fs14 font-fw500 text-cHighlighted'>
              {data?.lisence_title === "null" || data?.lisence_title === null ?
                <CommonEmptyStatus /> : data?.lisence_title}</span>
            {data?.applyLicense_end_time ?
              <div className='font-fw400 text-cImportantText text-fs12'>
                {data?.applyLicense_end_time ?
                  dateDiffCalendar(new Date(), data?.applyLicense_end_time) + " remaining" : ""
                }
              </div> : ""}
            <div>
              {
                data?.applyLicense_status === 'requested' ||
                  data?.applyLicense_status === 'paid1' ||
                  data?.applyLicense_status === 'missing1' ||
                  data?.applyLicense_status === 'created' ||
                  data?.applyLicense_status === 'paid2' ||
                  data?.applyLicense_status === 'missing2'
                  ? ' (Applied)' : ''}</div>
          </div>
        </td>

        {/* status of account */}
        <td className='py-s10 border-r-[1px] px-s15 text-fs14 text-center text-cTextBody min-w-[10px] max-w-[100px]'>
          <div className='flex justify-center space-x-2.5 items-center'>
            <div className={`font-fw600 text-fs12 ${data?.is_active ? "text-cPassed" :
              "text-cCancelled"}`}>{data?.is_active ? "Active" : "Deactivate"}
            </div>
            <img className="mt-s5" src={iRightArrow} alt=""></img>
          </div>
        </td>
      </tr>
    </>
  )
}

export default InstructorTableRow;