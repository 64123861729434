import React from 'react'
import { useNavigate } from 'react-router-dom';
import { iCategoryDetail, iDrivingCard, iClassroomCard, iExternalCard } from '../../../App/Utility/source';
import useSchoolStore from '../../../App/Stores/SchoolStore';
import CommonEmptyStatus from '../../CommonEmptyStatus/CommonEmptyStatus';
import { toHoursAndMinutes } from '../../../App/Utility/UtilityFunctions';
import Image from '../../Image/Image';


const SchoolCategoryLessonTableRow = ({ data, index }) => {

  const navigate = useNavigate();

  const { setShowCategoryLessonDetailsModal, setSchoolCategoryLessonDetails } = useSchoolStore();

  return (
    <>
      <tr onClick={async () => {
        await setSchoolCategoryLessonDetails(data)
        setShowCategoryLessonDetailsModal(true);
      }}
        className={`border-b cursor-pointer border-collapse border-cNmSelect`} >
        <th className='font-normal py-s10 border-r m-2 text-center'>
          <span className='mr-s12 dm:mrs12 md:mr-0'>{index + 1}</span>
        </th>

        <td className='border-r-[1px] py-s10 text-left px-s10 max-w-[200px] min-w-[200px] truncate'>
          <div className='flex sm:flex-col md:flex-row'>
            <div className='min-w-[40px] max-w-[40px]'>
              <img className='w-s38 h-s38  grow-0' src={data?.type === 'driving' && iDrivingCard || data?.type === 'external' && iExternalCard || data?.type === 'classroom' && iClassroomCard} alt="" />
            </div>
            <div className='capitalize truncate flex items-center font-fw600 mx-s10  text-cMainBlack lg:text-fs14 sm:text-fs12 '>
              {data?.name ? data.name : <CommonEmptyStatus />}
            </div>
          </div>
        </td>

        <td className='p-s10 border-r-[1px]  text-fs14 text-center text-cTextBody max-w-[100px] min-w-[100px]'>
          <span className='font-fw400 text-cImportantText text-fs14'>{data?.duration ? toHoursAndMinutes(data?.duration) : <CommonEmptyStatus />}</span>
        </td>

        <td className='p-s10 border-r-[1px] text-fs14 text-center text-cTextBody max-w-[100px] min-w-[100px]'>
          <span className='justify-center my-s8 font-fw400  text-cImportantText lg:text-fs14 sm:text-fs12'>
            <span className={`font-fw600 text-fs14 ${data?.status
              ? "text-cPassed" : "text-cCancelled"}`}>{data?.status
                ? "Active" : "Deactivate"}</span>
          </span>
        </td>

        <td className='px-s10 border-r-[1px] text-center max-w-[100px] min-w-[100px]'>
          <div className='text-cBrandColor text-fs16 font-fw600 '> {data?.price ? `DKK ${data.price}` : <CommonEmptyStatus />}</div>
        </td>
      </tr>
    </>
  )
}

export default SchoolCategoryLessonTableRow;