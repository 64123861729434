import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { filterLicense } from '../../App/Stores/LicenseStore';
import { getSchoolList, searchSchoolList } from '../../App/Stores/SchoolStore';
import useUtilityStore from '../../App/Stores/UtilityStore';
import { iFilter, iFilterWhite } from '../../App/Utility/source';
import CommonButtonOutlined from '../../Components/Button/CommonButtonOutlined';
import CommonTable from '../../Components/Table/CommonTable';
import SchoolTableRow from '../../Components/Table/School/SchoolTableRow';
import useLayoutStore from './../../App/Stores/LayoutStore';
import useSchoolStore from './../../App/Stores/SchoolStore';
import { PageTitle } from './../../App/Utility/UtilityFunctions';
import BackLink from './../../Components/Pagination/BackLink';
import CommonTitle from './../../Components/Title/CommonTitle';
import FilterSchoolModal from './SchoolModal/FilterSchoolModal';

const School = () => {
  const { schoolPageUrl,
    setSchoolPageUrl,
    schoolListAll,
    setSchoolSearchKey,
    schoolSearchKey,
    setShowSchoolFilterModal,
    filterData,
    FilterSchoolActive,
    schoolTakeList,
  } = useSchoolStore()

  const location = useLocation();
  const { setLoading } = useUtilityStore();

  const { school_id } = useParams()

  const [searchValue] = useDebounce(schoolSearchKey, 500);

  useEffect(() => {
    smartSearchAllSchool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const smartSearchAllSchool = async () => {
    if (searchValue) {
      await searchSchoolList(searchValue, schoolPageUrl, schoolTakeList.take);
    } else {
      setLoading(true);
      if (schoolListAll.current_page) {
        let urlToLoad = schoolListAll.path + "?page=" + schoolListAll.current_page;
        // console.log("CURRENT PAGE LINK TO LOAD::::::", urlToLoad);
        await getSchoolList(urlToLoad, filterData, "", false);
        // setSchoolSearchKey("")
      } else {
        await getSchoolList("", filterData, "", false);
        // setSchoolSearchKey("")
      }
      setLoading(false);
    }
  }

  const schoolHeaders = [
    { index: 1, name: t("#") },
    { index: 2, name: t("Name") },
    { index: 3, name: t("CVR") },
    { index: 4, name: t("Email") },
    { index: 5, name: t("Phone") },
    { index: 6, name: t("License") },
    { index: 7, name: t("Status") },
  ];

  const { setBarTitle } = useLayoutStore();

  useEffect(() => {
    window.scrollTo(0, 0);
    PageTitle(t("School"));
    setBarTitle("School");
    fetchSchoolData();
  }, []);

  const fetchSchoolData = async () => {
    setLoading(true);
    await filterLicense("school", false);

    if (schoolSearchKey) {
      setLoading(false);
      await searchSchoolList("", schoolPageUrl, schoolTakeList.take)
    } else {
      await getSchoolList(schoolPageUrl, filterData, "", false);
    }
    setLoading(false);
  }

  return (
    <div className='mx-s12 md:mx-s32'>

      <CommonTitle title="School Management">
        {
          location.pathname === `/category/details/${school_id}/school` && <BackLink linksArray={[
            { label: "Category", linkTo: "/category" },
            { label: "Category Details", linkTo: `/category/details/${school_id}` },
            { label: "School", linkTo: `` }
          ]} />
        }
      </CommonTitle>

      {/* idea:     api call issue */}
      <FilterSchoolModal />

      <div className='overflow-hidden bg-cBrandColor2 rounded-br20'>
        <CommonTable
          topRightComponent={<TakeItem />}
          headers={schoolHeaders}
          tableTitle=""
          paginationObject={schoolListAll}

          paginationOnClick={async (url) => {
            setLoading(true);
            console.log("url", url);
            setSchoolPageUrl(url)
            if (searchValue) {
              await searchSchoolList(searchValue, url, schoolTakeList.take);
            } else await getSchoolList(url, filterData, "", false);
            setLoading(false);
          }}

          shoSearchBox={true}
          searchValue={schoolSearchKey}
          withClearSearch={true}
          onSearchClear={() => setSchoolSearchKey("")}
          searchOnChange={(e) => setSchoolSearchKey(e.target.value)}

          autoManageRow={true}
          TableRowComponent={SchoolTableRow}

          titleComponent={
            <>
              <CommonButtonOutlined
                iconLeft={iFilterWhite}
                iconLeftHover={iFilter}
                isFilterDot={FilterSchoolActive}
                colorType='primary'
                btnLabel="Filter"
                onClick={() => { setShowSchoolFilterModal(true) }}
              />
            </>
          }
        />
      </div>
    </div>
  );
};

export default School;


const TakeItem = () => {
  const { setLoading } = useUtilityStore();

  const { setSchoolTakeList, schoolTakeList, schoolSearchKey, filterData, setSchoolPageUrl } = useSchoolStore();
  return (
    <div className=''>
      <span className='pr-s10'>Show</span>
      <select id="cars"
        value={schoolTakeList.take ? schoolTakeList.take : 10}
        onChange={async (e) => {
          setLoading(true);
          setSchoolPageUrl("")
          await setSchoolTakeList("take", e.target.value);
          if (schoolSearchKey) {
            setLoading(false);
            await searchSchoolList("", "")
          } else {
            await getSchoolList("", filterData, "", false);
          }
          setLoading(false);

        }} className='pl-5 font-semibold border-2 h-s30 rounded-br5 select-style space-y-s5 border-cChipBorder'>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={schoolTakeList.take === 10}
          value={10}
        >10</option>

        <option
          className='py-s10 text-cHighlightedTexts'
          selected={schoolTakeList.take === 25}
          value={25}
        >25</option>
        <option
          className='py-s10 text-cHighlightedTexts'
          selected={schoolTakeList.take === 50}
          value={50}
        >50</option>
        <option
          className='py-s10 text-cMainBlack'
          selected={schoolTakeList.take === 100}
          value={100}
        >100</option>
      </select>   <span className='pl-s10'>Entries</span>

    </div>
  )
}